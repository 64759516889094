import { Box, Grid, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import InterestedEvents from "../../components/InterestedEvents";
import PageLayout from "../../components/PageLayout";
import PublicEvents from "../../components/PublicEvents";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function FindEventsPage() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <PageLayout title="Find Events">
      <Grid item>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Public Events" {...a11yProps(0)} />
            <Tab label="My Events" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <PublicEvents />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <InterestedEvents />
        </CustomTabPanel>
      </Grid>
    </PageLayout>
  );
}

export default FindEventsPage;
