import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import ROUTES from "./constants/route-paths";
import { AuthProvider } from "./contexts/AuthProvider";
import FindEventsPage from "./pages/FindEvents/FindEventsPage";
import HostedEventsPage from "./pages/HostedEvents/HostedEventsPage";
import LoginPage from "./pages/Login/LoginPage";
import PublicPage from "./pages/Public/PublicPage";
import SignupPage from "./pages/Signup/SignupPage";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout authentication={true}/>}>
          <Route path={ROUTES.LOGIN.path} element={<LoginPage />} />
          <Route path={ROUTES.SIGNUP.path} element={<SignupPage />} />
        </Route>
        <Route element={<Layout authentication={false}/>}>
          <Route path={ROUTES.PUBLIC.path} element={<PublicPage />} />
          <Route
            path={ROUTES.FIND_EVENTS.path}
            element={                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
              <RequireAuth>
                <FindEventsPage />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.HOST_EVENTS.path}
            element={
              <RequireAuth>
                <HostedEventsPage />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  );
}
