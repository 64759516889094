import { getCookie } from "../utils/authUtils";

const API_URL_BASE = process.env.REACT_APP_API_URL_BASE;
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

const fetchRetry = async (
  urlPath: string,
  options: RequestInit = {},
  retries = MAX_RETRIES,
  retryDelay = RETRY_DELAY
): Promise<Response> => {
  try {
    const response = await fetch(urlPath, options);

    // This is a temporary workaround for a known issue with NGINX returning 502 Bad Gateway
    if (!response.ok && response.status === 502) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    if (retries > 0) {
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
      return fetchRetry(urlPath, options, retries - 1);
    } else {
      throw error;
    }
  }
};

export const makeRequest = async (
  urlPath: string,
  method: string,
  body?: any,
  noAuth?: boolean
) => {
  
  const headers: any = {
    "Content-Type": "application/json",
  };

  if (!noAuth) {
    const token = getCookie("token");
    headers["Authorization"] = `Bearer ${token}`;
  } 

  const options: any = {
    method,
    headers,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const requestUrl = `${API_URL_BASE}/${urlPath}`;

  try {
    const response = await fetchRetry(requestUrl, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Request failed:", error);
    throw error;
  }
};
