import { Grid } from "@mui/material";
import { useState } from "react";
import PageLayout from "../../components/PageLayout";
import { CreateEvent } from "./CreateEvent";
import { HostedEvents } from "./HostedEvents";

const HostedEventsPage = () => {
  const [isEventsUpdated, setIsEventsUpdated] = useState(false);
  const handleEventCreated = () => {
    setIsEventsUpdated(true);
  };

  return (
    <PageLayout title="Host Events">
      <Grid item>
        <CreateEvent onEventCreated={handleEventCreated} />
      </Grid>
      <Grid item>
        <HostedEvents isEventsUpdated={isEventsUpdated} />
      </Grid>
    </PageLayout>
  );
};

export default HostedEventsPage;
