import Cookies from "js-cookie";

export function addCookie(key: string, value: string) {
  const isSecure = window.location.protocol === "https:";
  document.cookie =
    `${key}=${value}; path=/; samesite=strict` + (isSecure && "; secure");
}

export function getCookie(key: string) {
  return Cookies.get(key);
}

export function removeCookie(key: string) {
  Cookies.remove(key);
}

export const decodeToken = (token: string): any => {
  return JSON.parse(atob(token.split(".")[1]));
};
