import React, { useEffect } from "react";
import { generateToken } from "../services/authService";
import {
  addCookie,
  decodeToken,
  getCookie,
  removeCookie
} from "../utils/authUtils";

interface AuthContextType {
  username: string | null;
  login: (
    username: string,
    password: string,
    callback: () => void
  ) => Promise<void>;
  logout: (callback: () => void) => void;
  isLoading: boolean;
  isAuthentiated: boolean;
}

let AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [username, setUsername] = React.useState<string | null>(null);
  const [isAuthentiated, setIsAuthenticated] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const token = getCookie("token");
    if (token) {
      try {
        const decodedToken = decodeToken(token);
        if (decodedToken.exp * 1000 > Date.now()) {
          setUsername(decodedToken.sub);
          setIsAuthenticated(true);
        } else {
          logout(() => {});
        }
      } catch (error) {
        logout(() => {});
      }
    }
    setIsLoading(false);
  }, []);

  const login = async (
    username: string,
    password: string,
    callback: () => void
  ) => {
    try {
      const token = await generateToken(username, password);
      addCookie("token", token);
      const decodedToken = decodeToken(token);
      setUsername(decodedToken.sub);
      setIsAuthenticated(true);
      callback();
    } catch (error) {
      throw error;
    }
  };

  const logout = async (callback: () => void) => {
    try {
      setUsername(null);
      setIsAuthenticated(false);
      removeCookie("token");
      callback();
    } catch (error) {
      throw error;
    }
  };

  const value = {
    username,
    login,
    logout,
    isLoading,
    isAuthentiated: isAuthentiated
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;

}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
