import { Grid, Typography } from "@mui/material";

interface PageLayoutProps {
  children: React.ReactNode;
  title: string;
}

const PageLayout: React.FC<PageLayoutProps> = ({ title, children }) => {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h4" gutterBottom component="h2">
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={1}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PageLayout;
