import CheckIcon from "@mui/icons-material/Check";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Grid, Typography } from "@mui/material";
import type {} from "@mui/x-data-grid/themeAugmentation";
import dayjs, { Dayjs } from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import * as React from "react";
import { useEffect, useState } from "react";
import eventImage from "../assets/aj-soccer-field-comp.jpg.webp";
import { RequestMethod } from "../constants/RestMethods";
import { useAuth } from "../contexts/AuthProvider";
import { PickupEvent } from "../interfaces/PickupEvent";
import { makeRequest } from "../services/requestService";
import { mapEvent } from "../utils/eventUtils";
import EventCard from "./EventCard";

dayjs.extend(dayOfYear);

const InterestedEvents: React.FC = () => {
  const [pickupEvents, setPickupEvents] = useState<PickupEvent[]>([]);
  const auth = useAuth();

  const isSameDayOfYear = (date1: Dayjs, date2: Dayjs) => {
    // First check if the years are the same
    if (date1.year() !== date2.year()) {
      return false;
    }
    // Then check if the day of year is the same
    return date1.dayOfYear() === date2.dayOfYear();
  };

  const buttonData = [
    { key: "GOING", icon: <CheckIcon />, label: "Going" },
    { key: "INTERESTED", icon: <StarBorderIcon />, label: "Interested" },
    { key: "NOT_GOING", icon: <NotInterestedIcon />, label: "Not going" },
  ];

  useEffect(() => {
    makeRequest("events?completed=false", RequestMethod.GET)
      .then((response: any) => {
        setPickupEvents(response.events.map(mapEvent));
      })
      .catch((error: Error) => {
        console.error(error);
      });
  }, [auth.username]);

  return (
    <div>
      {pickupEvents.length === 0 ? (
        <Typography variant="h6">No events found.</Typography>
      ) : (
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 3, sm: 8, md: 12 }}
        >
          {pickupEvents.map((pickupEvent) => (
            <Grid item xs={3} sm={4} md={4} key={pickupEvent.id}>
              <EventCard
                pickupEvent={pickupEvent}
                auth={auth}
                eventImage={eventImage}
                isSameDayOfYear={isSameDayOfYear}
                buttonData={buttonData}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default InterestedEvents;
