const ROUTES = {
  PUBLIC: {
    path: "/",
    title: "Public",
  },
  FIND_EVENTS: {
    path: "/find-events",
    title: "Find events",
  },
  HOST_EVENTS: {
    path: "/host-events",
    title: "Host events",
  },
  LOGIN: {
    path: "/login",
    title: "Log in",
  },
  SIGNUP: {
    path: "/signup",
    title: "Sign up",
  },
};

export default ROUTES;
