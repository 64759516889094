import { Navigate, useLocation } from "react-router-dom";
import ROUTES from "../constants/route-paths";
import { useAuth } from "../contexts/AuthProvider";

export default function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (auth.isLoading) {
    return null;
  }

  if (!auth.isAuthentiated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate to={ROUTES.LOGIN.path} state={{ from: location }} replace />
    );
  }

  return children;
}
