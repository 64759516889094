import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ROUTES from "../constants/route-paths";
import { useAuth } from "../contexts/AuthProvider";
import { signUp } from "../services/authService";

interface AuthFormProps {
  isSignup: boolean;
}

const AuthForm: React.FC<AuthFormProps> = ({ isSignup }) => {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  let from = location.state?.from?.pathname || ROUTES.FIND_EVENTS.path;

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<
    "success" | "error" | "info" | "warning" | undefined
  >("success");

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let username = formData.get("username") as string;
    let password = formData.get("password") as string;

    const signInAndRedirect = async () => {
      try {
        await auth.login(username, password, () => {
          navigate(from, { replace: true });
        });
      } catch (error) {
        setMessage("An error occurred during login. Please try again.");
        setSeverity("error");
        setOpen(true);
      }
    };

    try {
      if (isSignup) {
        let displayName = formData.get("displayName") as string;
        await signUp(username, password, displayName);
        setMessage("Account created successfully! Signing in...");
        setSeverity("success");
        setOpen(true);
        setTimeout(signInAndRedirect, 2000);
      } else {
        signInAndRedirect();
      }
    } catch (error) {
      setMessage("An error occured during account creation. Please try again.");
      setSeverity("error");
      setOpen(true);
    }
  }

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [displayName, setDisplayName] = useState<string>("");

  function handleUsernameChange(event: ChangeEvent<HTMLInputElement>): void {
    setUsername(event.target.value);
  }

  function handlePasswordChange(event: ChangeEvent<HTMLInputElement>): void {
    setPassword(event.target.value);
  }

  const handleDisplayNameChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setDisplayName(event.target.value);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      <Container maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {isSignup ? "Sign up" : "Log in"}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoFocus
              value={username}
              type="text"
              onChange={handleUsernameChange}
            />
            {isSignup && (
              <TextField
                margin="normal"
                fullWidth
                id="displayName"
                label="Display Name"
                name="displayName"
                autoFocus
                value={displayName}
                type="text"
                onChange={handleDisplayNameChange}
              />
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={handlePasswordChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {isSignup ? "Sign Up" : "Log In"}
            </Button>
            <Link to={isSignup ? ROUTES.LOGIN.path : ROUTES.SIGNUP.path}>
              {isSignup
                ? "Already have an account? Sign in."
                : "Don't have an account? Sign up."}
            </Link>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default AuthForm;
