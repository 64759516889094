import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DescriptionIcon from "@mui/icons-material/Description";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PeopleIcon from "@mui/icons-material/People";
import { CardActions, CardMedia, Chip, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import type {} from "@mui/x-data-grid/themeAugmentation";
import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import * as React from "react";
import { useState } from "react";
import { mapEvent } from "../../src/utils/eventUtils";
import { RequestMethod } from "../constants/RestMethods";
import { PickupEvent } from "../interfaces/PickupEvent";
import { makeRequest } from "../services/requestService";

dayjs.extend(dayOfYear);

interface EventCardProps {
  pickupEvent: PickupEvent;
  auth: any;
  eventImage: string;
  isSameDayOfYear: (date1: dayjs.Dayjs, date2: dayjs.Dayjs) => boolean;
  buttonData: { key: string; label: string; icon: React.ReactNode }[];
}

const EventCard: React.FC<EventCardProps> = ({
  pickupEvent: initialPickupEvent,
  auth,
  eventImage,
  isSameDayOfYear,
  buttonData,
}) => {
  const [pickupEvent, setPickupEvent] = useState(initialPickupEvent);

  const handleRSVP = async (id: string, rsvpStatus: string) => {
    try {
      const response = await makeRequest(
        `events/${id}/rsvp`,
        RequestMethod.POST,
        {
          status: rsvpStatus,
        }
      );
      if (response) {
        const updatedEventResponse = await makeRequest(
          `events/${id}`,
          RequestMethod.GET
        );
        if (updatedEventResponse) {
          setPickupEvent(mapEvent(updatedEventResponse));
        }
      }
    } catch (error) {
      console.error("Failed to udpate RSVP status:", error);
      alert("Failed to udpate RSVP status. Please try again.");
    }
  };

  return (
    <Card
      variant="outlined"
      style={{
        aspectRatio: "1/1",
      }}
    >
      <CardMedia component={"img"} image={eventImage} height={100} />
      <CardContent>
        <Chip
          color="info"
          label={pickupEvent.sport}
          size="small"
          sx={{ mb: 0.5 }}
        />
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          {pickupEvent.name}
        </Typography>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1}>
            <CalendarMonthIcon />
            <Typography variant="subtitle2">
              {pickupEvent.startTime.format("ddd, MMM D, YYYY h:mm A")}
              {" to "}
              {isSameDayOfYear(pickupEvent.startTime, pickupEvent.endTime)
                ? pickupEvent.endTime.format("h:mm A")
                : pickupEvent.endTime.format("ddd, MMM D, YYYY h:mm A")}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <LocationOnIcon fontSize="small" color="error" />
            <Typography variant="body2">{pickupEvent.venue.name}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <PeopleIcon fontSize="small" />
            <Typography variant="body2">
              {"Min: "}
              {pickupEvent.settings.minGuests}
            </Typography>
            {pickupEvent.settings.maxGuests !== undefined && (
              <Typography variant="body2">
                {"Max: "}
                {pickupEvent.settings.maxGuests}
              </Typography>
            )}
            <Typography variant="body2">
              {"Attending: "}
              {pickupEvent.guestInformation?.attending.length}
            </Typography>
            {pickupEvent.guestInformation !== undefined &&
              pickupEvent.guestInformation.interested.length >= 1 && (
                <Typography variant="body2">
                  Interested: {pickupEvent.guestInformation.interested.length}
                </Typography>
              )}
          </Stack>
          <Stack direction="row" spacing={1}>
            <ManageAccountsIcon fontSize="small" />
            <Typography variant="body2">
              Host:{" "}
              {pickupEvent.hostInformation.hosts.length > 0 &&
                pickupEvent.hostInformation.hosts[0]}
            </Typography>
          </Stack>
          {pickupEvent.description !== "" && (
            <Stack direction="row" spacing={1}>
              <DescriptionIcon fontSize="small" />
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 1.5 }}
              >
                {pickupEvent.description}
              </Typography>
            </Stack>
          )}
        </Stack>
      </CardContent>
      {auth.username && (
        <CardActions>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              "& > *": {
                m: 1,
              },
            }}
          >
            <ButtonGroup size="small">
              {buttonData.map((button) => (
                <Button
                  key={button.key}
                  startIcon={button.icon}
                  style={
                    (button.key === "GOING" &&
                      pickupEvent.guestInformation?.attending.includes(
                        auth.username!
                      )) ||
                    (button.key === "INTERESTED" &&
                      pickupEvent.guestInformation?.interested.includes(
                        auth.username!
                      )) ||
                    (button.key === "NOT_GOING" &&
                      pickupEvent.guestInformation?.notAttending.includes(
                        auth.username!
                      ))
                      ? { backgroundColor: "lightblue" }
                      : {}
                  }
                  onClick={() => handleRSVP(pickupEvent.id, button.key)}
                >
                  {button.label}
                </Button>
              ))}
            </ButtonGroup>
          </Box>
        </CardActions>
      )}
    </Card>
  );
};

export default EventCard;
