import { Grid } from "@mui/material";
import PageLayout from "../../components/PageLayout";
import PublicEvents from "../../components/PublicEvents";

function PublicPage() {
  return (
    <PageLayout title="Welcome">
      <Grid item>
        <PublicEvents />
      </Grid>
    </PageLayout>
  );
}

export default PublicPage;
