import dayjs from "dayjs";
import { PickupEvent } from "../interfaces/PickupEvent";

export const mapEvent = (pickupEvent: any): PickupEvent => {
  return {
    id: pickupEvent.id,
    name: pickupEvent.name,
    description: pickupEvent.description,
    sport: pickupEvent.sport,
    venue: {
      name: pickupEvent.venue.name,
      longitude: pickupEvent.venue.longitude,
      latitude: pickupEvent.venue.latitude,
    },
    startTime: dayjs(pickupEvent.start_time),
    endTime: dayjs(pickupEvent.end_time),
    settings: {
      privacy: pickupEvent.settings.privacy,
      minGuests: pickupEvent.settings.min_guests,
      maxGuests: pickupEvent.settings.max_guests,
    },
    hostInformation: { hosts: pickupEvent.host_information.hosts },
    guestInformation: {
      attending: pickupEvent.guest_information.attending,
      interested: pickupEvent.guest_information.interested,
      notAttending: pickupEvent.guest_information.not_attending,
    },
  };
};
