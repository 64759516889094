import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimeValidationError } from "@mui/x-date-pickers/models";
import dayjs, { Dayjs } from "dayjs";
import * as React from "react";
import { useState } from "react";
import { RequestMethod } from "../../constants/RestMethods";
import { makeRequest } from "../../services/requestService";

type CreateEventProps = {
  onEventCreated: () => void;
};

export const CreateEvent: React.FC<CreateEventProps> = ({ onEventCreated }) => {
  const [open, setOpen] = React.useState(false);
  const [sport] = React.useState("soccer");
  const [eventName, setEventName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [venueName, setVenueName] = React.useState("");
  const [minGuests, setMinGuests] = React.useState(3);
  const [maxGuests, setMaxGeusts] = React.useState<number | undefined>(
    undefined
  );
  const [privacy, setPrivacy] = React.useState("PUBLIC");
  function handlePrivacyChange(event: SelectChangeEvent<string>): void {
    setPrivacy(event.target.value as string);
  }
  const latitude = 0;
  const longitude = 0;
  // Set the start time to be 1 hour from now and round to the nearest hour
  const [startDateTime, setStartDateTime] = useState<Dayjs | null>(
    dayjs().add(1, "hour").minute(0).second(0).millisecond(0)
  );
  const [endDateTime, setEndDateTime] = useState<Dayjs | null>(
    dayjs().add(2, "hour").minute(0).second(0).millisecond(0)
  );

  const [startDateTimeError, setStartDateTimeError] =
    React.useState<DateTimeValidationError | null>(null);
  const [endDateTimeError, setEndDateTimeError] =
    React.useState<DateTimeValidationError | null>(null);

  const startDateTimeErrorMessage = React.useMemo(() => {
    switch (startDateTimeError) {
      case "disablePast": {
        return "Date cannot be in the past";
      }

      case "invalidDate": {
        return "Invalid date";
      }

      default: {
        return "";
      }
    }
  }, [startDateTimeError]);

  const endDateTimeErrorMessage = React.useMemo(() => {
    switch (endDateTimeError) {
      case "disablePast": {
        return "Date cannot be in the past";
      }

      case "minDate": {
        return "Date cannot be earlier than the start date";
      }

      case "invalidDate": {
        return "Invalid date";
      }

      default: {
        return "";
      }
    }
  }, [endDateTimeError]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    // Reset the form
    setEventName("");
    setDescription("");
    setVenueName("");
    setMinGuests(3);
    setPrivacy("PUBLIC");
    setStartDateTime(dayjs().add(1, "hour").minute(0).second(0).millisecond(0));
    setEndDateTime(dayjs().add(1, "hour").minute(0).second(0).millisecond(0));
    setMaxGeusts(undefined);
  };

  // When the user clicks save, send a POST request to the backend
  const sendCreateRequest = () => {
    const body = {
      name: eventName,
      description: description,
      sport: sport,
      start_time: startDateTime ? startDateTime.valueOf() : null,
      end_time: endDateTime ? endDateTime.valueOf() : null,
      venue: {
        name: venueName,
        latitude: latitude,
        longitude: longitude,
      },
      settings: {
        privacy: privacy,
        min_guests: minGuests,
        max_guests: maxGuests,
      },
    };
    makeRequest("events", RequestMethod.POST, body)
      .then(() => {
        // Call the onEventCreated callback to trigger a re-render of PickupEvents
        onEventCreated();

        // Show a success message
        alert("Event added successfully!");

        // Close the dialog
        handleClose();
      })
      .catch(() => {
        alert("Failed to add event. Please try again.");
      });
  };

  let minGuestsHelperText = () => {
    if (!minGuests) {
      return "Minimum guests is required";
    } else if (minGuests < 3) {
      return "Minimum guests cannot be less than 3";
    } else {
      return undefined;
    }
  };

  let maxGuestsHelperText = () => {
    if (maxGuests === undefined) {
      return undefined;
    } else if (maxGuests < 1) {
      return "Maximum guests is required";
    } else if (maxGuests < minGuests) {
      return "Maximum guests cannot be less than minimum guests";
    }
  };

  return (
    <>
      <Button onClick={handleOpen} variant="outlined">
        Create
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
        <DialogTitle>Create event</DialogTitle>
        <DialogContent>
          <Stack spacing={1.5}>
            <TextField
              margin="dense"
              label="Title"
              fullWidth
              required
              value={eventName}
              onChange={(event) => {
                setEventName(event.target.value);
              }}
              style={{ marginTop: "2" }}
            />
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              required={false}
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              // type allows multiline text
              type="text"
            />
            <TextField
              disabled
              margin="dense"
              label={"Sport"}
              fullWidth
              value={sport.charAt(0).toUpperCase() + sport.slice(1)}
            />
            <TextField
              margin="dense"
              label="Location"
              fullWidth
              required
              value={venueName}
              onChange={(event) => {
                setVenueName(event.target.value);
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                disablePast
                label="Starts"
                onChange={(newValue) => setStartDateTime(newValue)}
                onError={(newError) => setStartDateTimeError(newError)}
                slotProps={{
                  textField: {
                    helperText: startDateTimeErrorMessage,
                  },
                }}
                value={startDateTime}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                disablePast
                label={"Ends"}
                minDateTime={startDateTime}
                onChange={(newValue) => setEndDateTime(newValue)}
                onError={(newError) => setEndDateTimeError(newError)}
                slotProps={{
                  textField: {
                    helperText: endDateTimeErrorMessage,
                  },
                }}
                value={endDateTime}
              />
            </LocalizationProvider>
            <TextField
              margin="dense"
              label={"Minimum guests"}
              fullWidth
              value={minGuests}
              onChange={(event) => {
                setMinGuests(parseInt(event.target.value));
              }}
              type="number"
              inputProps={{ min: 3 }}
              error={!minGuests || minGuests < 3}
              helperText={minGuestsHelperText()}
              required
            />
            <TextField
              margin="dense"
              label={"Maximum guests"}
              fullWidth
              value={maxGuests}
              onChange={(event) => {
                setMaxGeusts(parseInt(event.target.value));
              }}
              type="number"
              inputProps={{ min: minGuests }}
              error={
                maxGuests === undefined || maxGuests === null
                  ? false
                  : maxGuests < minGuests
              }
              helperText={maxGuestsHelperText()}
              required
            />
            <FormControl fullWidth required margin="dense">
              <InputLabel>Privacy</InputLabel>
              <Select
                value={privacy}
                onChange={handlePrivacyChange}
                label="Privacy"
                required
              >
                <MenuItem value={"PUBLIC"}>Public</MenuItem>
                <MenuItem value={"INVITATION_ONLY"}>Private</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={sendCreateRequest}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
